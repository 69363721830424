/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./alert-error.component";
import * as i3 from "ng-jhipster";
import * as i4 from "./alert.service";
import * as i5 from "../auth/auth-jwt.service";
import * as i6 from "../auth/principal.service";
import * as i7 from "@angular/router";
var styles_AlertErrorComponent = [];
var RenderType_AlertErrorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertErrorComponent, data: {} });
export { RenderType_AlertErrorComponent as RenderType_AlertErrorComponent };
function View_AlertErrorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "alert", [], [[8, "type", 0], [8, "className", 0]], [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_v.context.$implicit.close(_co.alerts) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "closeButton clickable"], ["style", "background: transparent; border:  1px solid #fff; color: #fff;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "ft-x"], ["style", "color: #fff;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.close(_co.alerts) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.type; var currVal_1 = i0.ɵinlineInterpolate(1, "alert alert-", _v.context.$implicit.type, ""); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.msg; _ck(_v, 3, 0, currVal_2); }); }
export function View_AlertErrorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "alerts"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "top right toast"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertErrorComponent_1)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alerts; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AlertErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-alert-error", [], null, null, null, View_AlertErrorComponent_0, RenderType_AlertErrorComponent)), i0.ɵdid(1, 180224, null, 0, i2.AlertErrorComponent, [i3.JhiAlertService, i3.JhiEventManager, i4.AlertService, i5.AuthServerProvider, i6.Principal, i7.Router], null, null)], null, null); }
var AlertErrorComponentNgFactory = i0.ɵccf("app-alert-error", i2.AlertErrorComponent, View_AlertErrorComponent_Host_0, {}, {}, []);
export { AlertErrorComponentNgFactory as AlertErrorComponentNgFactory };
