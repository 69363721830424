var LoadingService = /** @class */ (function () {
    function LoadingService() {
        this.countCreation = 0;
        this.countResolution = 0;
    }
    LoadingService.prototype.incrementCreation = function () {
        this.countCreation++;
    };
    LoadingService.prototype.incrementResolution = function () {
        this.countResolution++;
    };
    LoadingService.prototype.isLoading = function () {
        return this.countResolution < this.countCreation;
    };
    return LoadingService;
}());
export { LoadingService };
