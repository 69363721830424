import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
var AccountService = /** @class */ (function () {
    function AccountService(http) {
        this.http = http;
    }
    AccountService.prototype.get = function () {
        return this.http.get(environment.apiUrl + 'auth/me', { observe: 'response' });
    };
    AccountService.prototype.save = function (account) {
        return this.http.post(environment.apiUrl + 'auth/me', account, { observe: 'response' });
    };
    AccountService.prototype.editPwd = function (idUser, password) {
        return this.http.post(environment.apiUrl + 'auth/change_password/' + idUser, { password: password });
    };
    AccountService.prototype.acceptPrivacy = function () {
        return this.http.get(environment.apiUrl + 'auth/accept_privacy');
    };
    AccountService.prototype.searchAccess = function () {
        return this.http.get(environment.apiUrl + 'auth/access_list');
    };
    return AccountService;
}());
export { AccountService };
