import { JhiAlertService } from 'ng-jhipster';
import { HttpResponse } from '@angular/common/http';
import { AlertService } from '../alert/alert.service';
import { tap } from 'rxjs/operators';
var NotificationInterceptor = /** @class */ (function () {
    // tslint:disable-next-line: no-unused-variable
    function NotificationInterceptor(injector) {
        var _this = this;
        this.injector = injector;
        setTimeout(function () { return _this.jhiAlertService = injector.get(JhiAlertService); });
        setTimeout(function () { return _this.alertService = injector.get(AlertService); });
    }
    NotificationInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        return next.handle(request).pipe(tap(function (event) {
            if (event instanceof HttpResponse) {
                // if (event.body.message) {
                //   this.alertService.success(event.body.message, null, 'top right');
                // }
                var arr = event.headers.keys();
                var alert_1 = null;
                var alertType_1 = null;
                arr.forEach(function (entry) {
                    if (entry.endsWith('app-alert')) {
                        alert_1 = event.headers.get(entry);
                    }
                    if (entry.endsWith('app-alert-type')) {
                        alertType_1 = event.headers.get(entry);
                    }
                });
                if (alert_1) {
                    if (typeof alert_1 === 'string') {
                        if (alert_1 !== 'The log has been saved') {
                            if (_this.alertService) {
                                _this.alertService.addAlert(_this.jhiAlertService.addAlert({
                                    type: alertType_1 === 'warning' ? 'warning' : 'success',
                                    msg: alert_1,
                                    timeout: 5000,
                                    toast: _this.jhiAlertService.isToast(),
                                    scoped: true,
                                    position: 'top right'
                                }, _this.alertService.getAlerts()));
                            }
                        }
                    }
                }
            }
        }, function (err) { }));
    };
    return NotificationInterceptor;
}());
export { NotificationInterceptor };
