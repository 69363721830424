import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {LoadingService} from './block/interceptor/loading.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {DeviceDetectorService} from 'ngx-device-detector';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit {

  @ViewChild('template')
  public template: ElementRef;
  modalRef: BsModalRef;

  constructor(
    private loadingService: LoadingService,
    private deviceService: DeviceDetectorService,
    private modalService: BsModalService,
    public translate: TranslateService
  ) { translate.setDefaultLang('en'); translate.addLangs(['en', 'it']);
  }

  ngOnInit() {
    const browser = this.deviceService.getDeviceInfo();
    const version = parseInt(browser.browser_version, 10);

    console.log(browser.browser);
    console.log(browser.browser_version);

    let ok = false;

    if (browser.browser.toLowerCase() === 'chrome') {
      // ok = version >= 45;
      ok = version >= 45;
    } else if (browser.browser.toLowerCase() === 'firefox') {
      ok = version >= 38;
    } else if (browser.browser.toLowerCase() === 'safari') {
      ok = version >= 9;
    } else if (browser.browser.toLowerCase() === 'opera') {
      ok = version >= 30;
    } else if (browser.browser.toLowerCase() === 'ie') {
      ok = version >= 10;
    } else if (browser.browser.toLowerCase() === 'edge') {
      ok = version >= 12;
    }
    if(!ok) {
      this.openModal();
    }
  }

  openModal() {
    this.modalRef = this.modalService.show(this.template, {class: 'modal-sm', backdrop: true, ignoreBackdropClick: true,});
  }

  isLoading() {
    return this.loadingService.isLoading();
  }
}
