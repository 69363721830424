import { OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
var ModalGenericComponent = /** @class */ (function () {
    function ModalGenericComponent(bsModalRef) {
        this.bsModalRef = bsModalRef;
        this.options = {
            buttons: []
        };
        this.onClose = new Subject();
    }
    ModalGenericComponent.prototype.ngOnInit = function () {
    };
    ModalGenericComponent.prototype.clickedButton = function (button) {
        if (this.options.isNavigate) {
            if (button === 'Stay') {
                this.onClose.next(false);
            }
            else {
                this.onClose.next(true);
            }
        }
        else {
            this.onClose.next(button);
        }
        this.bsModalRef.hide();
    };
    return ModalGenericComponent;
}());
export { ModalGenericComponent };
