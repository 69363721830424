import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { LicenseManager } from 'ag-grid-enterprise/main';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
LicenseManager
    .setLicenseKey('Iperdesign_snc__MultiApp_1Devs24_October_2019__MTU3MTg3MTYwMDAwMA==fe0d8ca11e83bf22e4e95f84493b1fa7');
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(function (err) { return console.error(err); });
