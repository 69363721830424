import {Component, OnInit} from '@angular/core';
import {JhiEventManager} from 'ng-jhipster';
import {Router} from '@angular/router';
import {AuthServerProvider} from '../../block/auth/auth-jwt.service';
import {Principal} from '../../block/auth/principal.service';
import {AccountService} from '../../block/auth/account.service';
import {BsModalRef} from 'ngx-bootstrap';
import {ModalGenericService} from '../../shared/modal-generic/modal-generic.service';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  bsModalRef: BsModalRef;
  account: any;
  isMobile: any;
  constructor(
    private authServerProvider: AuthServerProvider,
    private eventManager: JhiEventManager,
    private principal: Principal,
    private accountService: AccountService,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private genericModalService: ModalGenericService,
    private router: Router,
    public translate: TranslateService,
    private deviceService: DeviceDetectorService
  ) {
  }

  ngOnInit() {
    this.translate.addLangs(['en', 'it']);
    if(this.localStorage.retrieve('language') == 'it' || this.sessionStorage.retrieve('language') == 'it') {
      this.translate.use('it');
    }else{
      this.translate.use('en');
    }
    this.getAccount();
    this.registerAuthenticationSuccess();
    this.isMobile = this.deviceService.isMobile();
  }

  private getAccount() {
    if(this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken')) {
      this.principal.identity().then((account) => {
        this.account = account;
        /* if (this.account.loggedUser.privacyPolicy === null) {
          this.bsModalRef = this.genericModalService.open({
            title: 'Accept Privacy',
            closeButton: null,
            message: this.account.privacyText.replace('https://www.newton.it/cookie.php', '&nbsp;<a href="https://www.newton.it/cookie.php" target="_blank">https://www.newton.it/cookie.php</a>&nbsp;'),
            buttons: ['Yes']
          });
          this.bsModalRef.content.onClose.subscribe(result => {
            console.log('ok');
            this.accountService.acceptPrivacy().subscribe( (data: any) => {
            });
          });
        } */
      });
    }
  }

  registerAuthenticationSuccess() {
    this.eventManager.subscribe('authenticationSuccess', (message) => {
      this.principal.identity(true).then((account) => {
        this.account = account;
      });
    });
  }

  logout() {
    this.authServerProvider.logout().subscribe(res => {});
    this.principal.authenticate(null);
    this.account = null;
    this.router.navigate(['/landing']);
  }

  loginAs(id?: number) {
    this.authServerProvider.loginAs(id).subscribe(res => {
      this.eventManager.broadcast({
        name: 'authenticationSuccess',
        content: 'Sending Authentication Success'
      });
    });
  }

  changeLang(lang){
    this.translate.use(lang);
    this.localStorage.store('language', lang);
    this.sessionStorage.store('language', lang);
  }
}
