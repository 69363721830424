import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
var AuthService = /** @class */ (function () {
    function AuthService($localStorage, $sessionStorage) {
        this.$localStorage = $localStorage;
        this.$sessionStorage = $sessionStorage;
    }
    AuthService.prototype.signupUser = function (email, password) {
        // your code for signing up the new user
    };
    AuthService.prototype.signinUser = function (email, password) {
        // your code for checking credentials and getting tokens for for signing in user
    };
    AuthService.prototype.logout = function () {
        this.token = null;
    };
    AuthService.prototype.getToken = function () {
        return this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken');
    };
    AuthService.prototype.isAuthenticated = function () {
        // here you can check if user is authenticated or not through his token
        return true;
        // return false;
    };
    return AuthService;
}());
export { AuthService };
