import { OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(deviceService) {
        this.deviceService = deviceService;
    }
    FooterComponent.prototype.ngOnInit = function () {
        this.isMobile = this.deviceService.isMobile();
    };
    return FooterComponent;
}());
export { FooterComponent };
