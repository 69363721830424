import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
var AuthServerProvider = /** @class */ (function () {
    function AuthServerProvider(http, $localStorage, $sessionStorage) {
        this.http = http;
        this.$localStorage = $localStorage;
        this.$sessionStorage = $sessionStorage;
    }
    AuthServerProvider.prototype.getToken = function () {
        return this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken');
    };
    AuthServerProvider.prototype.login = function (credentials) {
        var data = {
            username: credentials.username,
            password: credentials.password,
        };
        return this.http.post(environment.apiUrl + 'auth/login/client', data, { observe: 'response' }).pipe(map(authenticateSuccess.bind(this)));
        function authenticateSuccess(resp) {
            var bearerToken = resp.headers.get('Authorization');
            if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
                var jwt = bearerToken.slice(7, bearerToken.length);
                this.storeAuthenticationToken(jwt, true);
                return resp.body;
            }
        }
    };
    AuthServerProvider.prototype.loginAs = function (id) {
        return this.http.get(environment.apiUrl + 'auth/login/as/' + id, { observe: 'response' }).pipe(map(authenticateSuccess.bind(this)));
        function authenticateSuccess(resp) {
            var bearerToken = resp.headers.get('Authorization');
            if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
                var jwt = bearerToken.slice(7, bearerToken.length);
                this.storeAuthenticationToken(jwt, true);
                this.storeRefreshUser(true);
                return resp.body;
            }
        }
    };
    AuthServerProvider.prototype.loginWithToken = function (jwt, rememberMe) {
        if (jwt) {
            this.storeAuthenticationToken(jwt, rememberMe);
            return Promise.resolve(jwt);
        }
        else {
            return Promise.reject('auth-jwt-service Promise reject'); // Put appropriate error message here
        }
    };
    AuthServerProvider.prototype.initResetPassword = function (body) {
        return this.http.post(environment.apiUrl + 'auth/reset_password/', body);
    };
    AuthServerProvider.prototype.finishResetPassword = function (body) {
        return this.http.post(environment.apiUrl + 'auth/new_password/', body);
    };
    AuthServerProvider.prototype.storeRefreshUser = function (value) {
        this.$localStorage.store('authenticationTokenRefresh', value);
        // } else {
        this.$sessionStorage.store('authenticationTokenRefresh', value);
    };
    AuthServerProvider.prototype.storeAuthenticationToken = function (jwt, rememberMe) {
        // if (rememberMe) {
        this.$localStorage.store('authenticationToken', jwt);
        // } else {
        this.$sessionStorage.store('authenticationToken', jwt);
        // }
    };
    AuthServerProvider.prototype.logout = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this.$localStorage.clear('authenticationToken');
            _this.$sessionStorage.clear('authenticationToken');
            observer.complete();
        });
    };
    return AuthServerProvider;
}());
export { AuthServerProvider };
