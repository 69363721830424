<!--Footer Starts-->
<footer style="position: fixed; bottom: 0; left: 0; right: 0; z-index: 10;">
    <div class="container-fluid" style="background-color: black; height:30px">
      <div class="copyright">
        <div class="left-text" style="float: left; padding-left: 80px;" >
          <p style="color: white;  margin: 0; line-height: 30px; font-size: 12px !important;" >&copy; 2020 <a class="link" href="https://unirufa.it" style="color: white"><b>RUFA <span *ngIf="!isMobile">- Rome University of Fine Arts</span></b></a></p>
        </div>
        <div style="float: right; margin: 0">
          <a class="link" href="https://iperdesign.com" target="_blank" style="color: white"><img src="assets/img/iperdesign-logo.svg" title="Design and develop with love by iperdesign" alt="ID"></a>
        </div>
      </div>
    </div>
</footer>
<!--Footer Ends-->
