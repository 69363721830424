
    <div class="alerts" role="alert">
      <!--<div *ngFor="let alert of alerts" class="{{alert.position}}" [ngClass]="{'toast': alert.toast}">-->
      <div class="top right toast">
        <alert *ngFor="let alert of alerts" [type]="alert.type" class="alert alert-{{alert.type}}" (close)="alert.close(alerts)">
          <a class="closeButton clickable" style="background: transparent; border:  1px solid #fff; color: #fff;">
            <i class="ft-x" (click)="alert.close(alerts)" style="color: #fff;"></i></a>
          <span [innerHTML]="alert.msg"></span>
        </alert>
      </div>
    </div>