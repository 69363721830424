import { Component, OnInit } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-modal-generic',
  templateUrl: './modal-generic.component.html'
})
export class ModalGenericComponent implements OnInit {

  options: any = {
    buttons: []
  };
  public onClose: Subject<boolean>;

  constructor(
    public bsModalRef: BsModalRef,
  ) {
    this.onClose = new Subject();
  }

  ngOnInit() {
  }

  clickedButton(button: any) {
    if (this.options.isNavigate) {
      if (button === 'Stay') {
        this.onClose.next(false);
      } else {
        this.onClose.next(true);
      }
    } else {
      this.onClose.next(button);
    }
    this.bsModalRef.hide();
  }
}
