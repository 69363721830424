
<div class="wrapper">
    <app-navbar></app-navbar>
    <div class="main-panel">
        <div class="main-content">
            <div class="content-wrapper">
                <div class="container-fluid">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>


