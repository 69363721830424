/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "./navbar.component";
import * as i5 from "../../block/auth/auth-jwt.service";
import * as i6 from "ng-jhipster";
import * as i7 from "../../block/auth/principal.service";
import * as i8 from "../../block/auth/account.service";
import * as i9 from "ngx-webstorage";
import * as i10 from "../../shared/modal-generic/modal-generic.service";
import * as i11 from "@angular/router";
import * as i12 from "@ngx-translate/core";
import * as i13 from "ngx-device-detector";
var styles_NavbarComponent = [i0.styles];
var RenderType_NavbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
function View_NavbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "navbar-text black"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "d-inline-block"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(2, 212992, null, 2, i2.NgbDropdown, [i1.ChangeDetectorRef, i2.NgbDropdownConfig, i3.DOCUMENT, i1.NgZone, i2.ɵz], null, null), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _anchor: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 3, "p", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "dropdown-toggle"], ["id", "dropdownBasic"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).toggleOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.ɵr, [i2.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[2, 4]], i2.ɵq, null, [i2.ɵr]), (_l()(), i1.ɵted(8, null, ["", " ", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["aria-labelledby", "dropdownBasic"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], null, null, null, null)), i1.ɵdid(10, 16384, [[1, 4]], 0, i2.ɵp, [i2.NgbDropdown, i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [["class", "dropdown-item"], ["style", "margin-bottom: 0; cursor: pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Logout"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).isOpen(); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).dropdown.isOpen(); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.account.firstname; var currVal_3 = _co.account.lastname; _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_4 = true; var currVal_5 = i1.ɵnov(_v, 10).dropdown.isOpen(); var currVal_6 = i1.ɵnov(_v, 10).placement; _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6); }); }
function View_NavbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "black"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon-logout lang-text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_NavbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "nav", [["class", "navbar navbar-expand-lg navbar-light bg-light"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "navbar-brand"], ["style", "cursor: default"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "RUFA Logo"], ["class", "logo-rufa"], ["src", "assets/img/rufa_logo.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "navbar-collapse"], ["style", "display: flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "RUFA Logo"], ["class", "logo-rufa"], ["src", "assets/img/rufa_logo_type.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "ul", [["class", "navbar-nav mr-auto"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_1)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_2)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "lang-it"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeLang("it") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ITA"])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "lang-en"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeLang("en") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ENG"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.account && !_co.isMobile); _ck(_v, 7, 0, currVal_0); var currVal_1 = (_co.account && _co.isMobile); _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.translate.currentLang == "it") ? "lang-text active" : "lang-text "); _ck(_v, 11, 0, currVal_2); var currVal_3 = ((_co.translate.currentLang == "en") ? "lang-text active" : "lang-text "); _ck(_v, 15, 0, currVal_3); }); }
export function View_NavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i1.ɵdid(1, 114688, null, 0, i4.NavbarComponent, [i5.AuthServerProvider, i6.JhiEventManager, i7.Principal, i8.AccountService, i9.LocalStorageService, i9.SessionStorageService, i10.ModalGenericService, i11.Router, i12.TranslateService, i13.DeviceDetectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarComponentNgFactory = i1.ɵccf("app-navbar", i4.NavbarComponent, View_NavbarComponent_Host_0, {}, {}, []);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };
