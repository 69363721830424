import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
// import {LoadingService} from './loading.service';
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(localStorage, sessionStorage, loadingService, authServerProvider, route) {
        this.localStorage = localStorage;
        this.sessionStorage = sessionStorage;
        this.loadingService = loadingService;
        this.authServerProvider = authServerProvider;
        this.route = route;
    }
    AuthInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        if (!request || !request.url || (/^http/.test(request.url)
            && !(environment.apiUrl && environment.documentUrl
                && (request.url.startsWith(environment.apiUrl) || request.url.startsWith(environment.documentUrl))))) {
            return next.handle(request);
        }
        var token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');
        if (!!token) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token
                }
            });
        }
        if (!request.url.includes('autocomplete')) {
            this.loadingService.incrementCreation();
        }
        return next.handle(request).pipe(tap(function (event) {
            if (event.type) {
                if (!request.url.includes('autocomplete')) {
                    _this.loadingService.incrementResolution();
                }
            }
        }, function (err) {
            if (request) {
                if (!request.url.includes('autocomplete')) {
                    _this.loadingService.incrementResolution();
                }
            }
        }));
    };
    return AuthInterceptor;
}());
export { AuthInterceptor };
