<div id="loadessr" [hidden]="!isLoading()">
  <div id="loader"></div>
</div>
<app-alert-error></app-alert-error>
<router-outlet></router-outlet>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Alert!</h4>
  </div>
  <div class="modal-body">
    oooops! The browser you're using can not support The Cage!<br>We kindly advice (suggest) you to use the last version of Chrome, Firefox or Safari <br>or to download the Cage App available for both Andoid 6 and iOS 12
  </div>
</ng-template>
