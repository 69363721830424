import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Injectable} from '@angular/core';
import {Principal} from '../../block/auth/principal.service';

@Injectable()
export class AgGridStateManagerService {

  account: any;

  constructor(
    private $localStorage: LocalStorageService,
    private $sessionStorage: SessionStorageService,
    private principal: Principal
  ) {
    this.getAccount();
  }

  saveOrder(gridName: string, gridState: any) {
    this.$localStorage.store(gridName + '_order_' + this.account.id, gridState);
    this.$sessionStorage.store(gridName + '_order_' + this.account.id, gridState);
  }

  getOrder(gridName: string) {
    return  this.$sessionStorage.retrieve(gridName + '_order_' + this.account.id) ||
      this.$localStorage.retrieve(gridName + '_order_' + this.account.id);
  }


  saveState(gridName: string, gridState: any) {
    this.$localStorage.store(gridName + '_state_' + this.account.id, gridState);
    this.$sessionStorage.store(gridName + '_state_' + this.account.id, gridState);
  }

  getState(gridName: string) {
    return  this.$sessionStorage.retrieve(gridName + '_state_' + this.account.id) ||
            this.$localStorage.retrieve(gridName + '_state_' + this.account.id);
  }

  saveGroup(gridName: string, gridState: any) {
    this.$localStorage.store(gridName + '_group_' + this.account.id, gridState);
    this.$sessionStorage.store(gridName + '_group_' + this.account.id, gridState);
  }

  getGroup(gridName: string) {
    return  this.$sessionStorage.retrieve(gridName + '_group_' + this.account.id) ||
            this.$localStorage.retrieve(gridName + '_group_' + this.account.id) || [];
  }

  resetState(gridName: string) {
    this.$localStorage.clear(gridName + '_' + this.account.id);
    this.$sessionStorage.clear(gridName + '_' + this.account.id);
  }

  private getAccount() {
    this.principal.identity().then((account) => {
      this.account = account;
    });
  }
}
