import { Routes, RouterModule } from '@angular/router';

//  Route for content layout with sidebar, navbar and footer
export const FULL_ROUTES: Routes = [
  {
    path: 'login',
    loadChildren: './modules/login/login.module#LoginModule'
  },
  {
    path: 'contest-list',
    loadChildren: './contest-list/contest-list.module#ContestListModule'
  },
  {
    path: 'phase',
    loadChildren: './phase/phase.module#PhaseModule'
  },
  {
    path: 'contest',
    loadChildren: './contest/contest.module#ContestModule'
  },
  {
    path: 'project',
    loadChildren: './project/project.module#ProjectModule'
  },
  {
    path: 'contest_evaluation',
    loadChildren: './evaluation/evaluation.module#EvaluationModule'
  },
];
