import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import {LicenseManager} from 'ag-grid-enterprise/main';

LicenseManager
  .setLicenseKey('Iperdesign_snc__MultiApp_1Devs24_October_2019__MTU3MTg3MTYwMDAwMA==fe0d8ca11e83bf22e4e95f84493b1fa7');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
