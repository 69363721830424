/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./block/alert/alert-error.component.ngfactory";
import * as i3 from "./block/alert/alert-error.component";
import * as i4 from "ng-jhipster";
import * as i5 from "./block/alert/alert.service";
import * as i6 from "./block/auth/auth-jwt.service";
import * as i7 from "./block/auth/principal.service";
import * as i8 from "@angular/router";
import * as i9 from "./app.component";
import * as i10 from "./block/interceptor/loading.service";
import * as i11 from "ngx-device-detector";
import * as i12 from "ngx-bootstrap/modal";
import * as i13 from "@ngx-translate/core";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Alert!"])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" oooops! The browser you're using can not support The Cage!"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We kindly advice (suggest) you to use the last version of Chrome, Firefox or Safari "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["or to download the Cage App available for both Andoid 6 and iOS 12 "]))], null, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { template: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["id", "loadessr"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["id", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-alert-error", [], null, null, null, i2.View_AlertErrorComponent_0, i2.RenderType_AlertErrorComponent)), i1.ɵdid(4, 180224, null, 0, i3.AlertErrorComponent, [i4.JhiAlertService, i4.JhiEventManager, i5.AlertService, i6.AuthServerProvider, i7.Principal, i8.Router], null, null), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, [[1, 2], ["template", 2]], null, 0, null, View_AppComponent_1))], function (_ck, _v) { _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoading(); _ck(_v, 1, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i9.AppComponent, [i10.LoadingService, i11.DeviceDetectorService, i12.BsModalService, i13.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i9.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
