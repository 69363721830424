import {Component, OnInit} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit{
  isMobile: any;
  constructor(
    private deviceService: DeviceDetectorService
  ) {
  }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
  }
}
