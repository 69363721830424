import {Routes, RouterModule} from '@angular/router';

// Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
  {
    path: 'login',
    loadChildren: './modules/login/login.module#LoginModule'
  },
  {
    path: 'contest-list',
    loadChildren: './contest-list/contest-list.module#ContestListModule'
  },
  {
    path: 'landing',
    loadChildren: './landing-page/landing-page.module#LandingPageModule'
  },
];
