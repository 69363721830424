/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./full-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../navbar/navbar.component.ngfactory";
import * as i3 from "../navbar/navbar.component";
import * as i4 from "../../block/auth/auth-jwt.service";
import * as i5 from "ng-jhipster";
import * as i6 from "../../block/auth/principal.service";
import * as i7 from "../../block/auth/account.service";
import * as i8 from "ngx-webstorage";
import * as i9 from "../../shared/modal-generic/modal-generic.service";
import * as i10 from "@angular/router";
import * as i11 from "@ngx-translate/core";
import * as i12 from "ngx-device-detector";
import * as i13 from "../footer/footer.component.ngfactory";
import * as i14 from "../footer/footer.component";
import * as i15 from "./full-layout.component";
var styles_FullLayoutComponent = [i0.styles];
var RenderType_FullLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FullLayoutComponent, data: {} });
export { RenderType_FullLayoutComponent as RenderType_FullLayoutComponent };
export function View_FullLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(2, 114688, null, 0, i3.NavbarComponent, [i4.AuthServerProvider, i5.JhiEventManager, i6.Principal, i7.AccountService, i8.LocalStorageService, i8.SessionStorageService, i9.ModalGenericService, i10.Router, i11.TranslateService, i12.DeviceDetectorService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "main-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "main-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i10.RouterOutlet, [i10.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-footer", [], null, null, null, i13.View_FooterComponent_0, i13.RenderType_FooterComponent)), i1.ɵdid(10, 114688, null, 0, i14.FooterComponent, [i12.DeviceDetectorService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 8, 0); _ck(_v, 10, 0); }, null); }
export function View_FullLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-full-layout", [], null, null, null, View_FullLayoutComponent_0, RenderType_FullLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i15.FullLayoutComponent, [], null, null)], null, null); }
var FullLayoutComponentNgFactory = i1.ɵccf("app-full-layout", i15.FullLayoutComponent, View_FullLayoutComponent_Host_0, {}, {}, []);
export { FullLayoutComponentNgFactory as FullLayoutComponentNgFactory };
