<style>
  .modal-body a{color:white !important;}
</style>
<div class="modal-header">
  <h4 class="modal-title pull-left">{{options.title}}</h4>
  <div class="close clickable" data-dismiss="modal" (click)="bsModalRef.hide()"></div>
</div>
<div class="modal-body" [innerHTML]="options.message">
</div>
<div class="modal-footer">
  <a class="btn btn-outline-danger" *ngIf="options.closeButton" (click)="bsModalRef.hide()">{{options.closeButton}}</a>
  <a *ngFor="let button of options.buttons" class="btn btn-outline-danger" (click)="clickedButton(button)">{{button}}</a>
</div>
