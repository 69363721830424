import { ModuleWithProviders } from '@angular/core';
import { ModalGenericService } from './modal-generic/modal-generic.service';
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    SharedModule.forRoot = function () {
        return {
            ngModule: SharedModule,
            providers: [ModalGenericService]
        };
    };
    return SharedModule;
}());
export { SharedModule };
