import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {Principal} from './principal.service';
import {StateStorageService} from './state-storage.service';
import * as _ from 'lodash';
import {AuthServerProvider} from './auth-jwt.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private principal: Principal,
    private stateStorageService: StateStorageService,
    private authServerProvider: AuthServerProvider,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(route.queryParams.token){
      this.authServerProvider.storeAuthenticationToken(route.queryParams.token, true)
    }
    return Promise.resolve(this.principal.identity().then((account) => {

      const roles = route.data['roles'] as Array<string>;

      if (account) {
        const permissionMatch = _.intersection([account.role], roles);

        if (permissionMatch.length) {
          if (!account.loggedAs || !account.loggedAs.permissions) {
            return true;
          } else {
            const loggedAsPermissionMatch = _.intersection(account.loggedAs.permissions, roles);
            if (loggedAsPermissionMatch.length) {
              return true;
            }
          }
        }
        /*else if (state.url.indexOf('dashboard') > -1 && _.intersection(account.permission, ['AS', 'C', 'S']).length) {
          this.router.navigate(['/servizi/tesseramento']);
          return false;
        } else if (state.url.indexOf('dashboard') > -1 && _.intersection(account.permission, ['T']).length) {
          this.router.navigate(['/servizi/corsi']);
          return false;
        } else if (state.url.indexOf('dashboard') > -1 && _.intersection(account.permission, ['A']).length) {
          this.router.navigate(['/contabilita']);
          return false;
        }*/
      }

      this.stateStorageService.storeUrl(null);
      this.router.navigate(['/login']);
      return false;

      // if (account) {
      //   const permissionMatch = _.intersection(account.permission, roles);
      //   if (permissionMatch.length) {
      //     return true;
      //   } else if (state.url.indexOf('dashboard') > -1 && _.intersection(account.permission, ['AS', 'C', 'S']).length) {
      //     this.router.navigate(['/servizi/tesseramento']);
      //     return false;
      //   }
      // }
      // this.stateStorageService.storeUrl(null);
      // this.router.navigate(['/login']);
      // return false;
    }));
  }
}
