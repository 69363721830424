import { Router } from '@angular/router';
import { Principal } from './principal.service';
import { StateStorageService } from './state-storage.service';
import * as _ from 'lodash';
import { AuthServerProvider } from './auth-jwt.service';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(principal, stateStorageService, authServerProvider, router) {
        this.principal = principal;
        this.stateStorageService = stateStorageService;
        this.authServerProvider = authServerProvider;
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        if (route.queryParams.token) {
            this.authServerProvider.storeAuthenticationToken(route.queryParams.token, true);
        }
        return Promise.resolve(this.principal.identity().then(function (account) {
            var roles = route.data['roles'];
            if (account) {
                var permissionMatch = _.intersection([account.role], roles);
                if (permissionMatch.length) {
                    if (!account.loggedAs || !account.loggedAs.permissions) {
                        return true;
                    }
                    else {
                        var loggedAsPermissionMatch = _.intersection(account.loggedAs.permissions, roles);
                        if (loggedAsPermissionMatch.length) {
                            return true;
                        }
                    }
                }
                /*else if (state.url.indexOf('dashboard') > -1 && _.intersection(account.permission, ['AS', 'C', 'S']).length) {
                  this.router.navigate(['/servizi/tesseramento']);
                  return false;
                } else if (state.url.indexOf('dashboard') > -1 && _.intersection(account.permission, ['T']).length) {
                  this.router.navigate(['/servizi/corsi']);
                  return false;
                } else if (state.url.indexOf('dashboard') > -1 && _.intersection(account.permission, ['A']).length) {
                  this.router.navigate(['/contabilita']);
                  return false;
                }*/
            }
            _this.stateStorageService.storeUrl(null);
            _this.router.navigate(['/login']);
            return false;
            // if (account) {
            //   const permissionMatch = _.intersection(account.permission, roles);
            //   if (permissionMatch.length) {
            //     return true;
            //   } else if (state.url.indexOf('dashboard') > -1 && _.intersection(account.permission, ['AS', 'C', 'S']).length) {
            //     this.router.navigate(['/servizi/tesseramento']);
            //     return false;
            //   }
            // }
            // this.stateStorageService.storeUrl(null);
            // this.router.navigate(['/login']);
            // return false;
        }));
    };
    return AuthGuard;
}());
export { AuthGuard };
