import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {ModalGenericComponent} from './modal-generic/modal-generic.component';
import {ModalGenericService} from './modal-generic/modal-generic.service';
import {BsModalService, ModalModule} from 'ngx-bootstrap';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DateCustomComponent} from './date-custom/date-custom.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {AnomaliesPopoverComponent} from './ag-grid-custom-components/anomalies-popover/popover.components';
import {SubscriberTitlesPopoverComponent} from './ag-grid-custom-components/subscriber-titles-popover/popover.components';
import {AgGridStateManagerService} from './services/agGridStateManager.service';
import {IconRenderer} from './ag-grid-custom-components/icon-renderer';
import {ImageRenderer} from './ag-grid-custom-components/image-renderer';
import {TranslateRenderer} from './ag-grid-custom-components/translate-renderer';
import {ActionProjectRenderer} from './ag-grid-custom-components/action-project-renderer';
import {IconEvalRenderer} from './ag-grid-custom-components/icon-eval-renderer';
import {ActionEvalRenderer} from './ag-grid-custom-components/action-eval-renderer';
import {ProjectPopoverComponent} from './ag-grid-custom-components/popover-project/popover-project.components';
import {EvaluatorPopoverComponent} from './ag-grid-custom-components/popover-evaluator/popover-evaluator.components';

@NgModule({
  declarations: [
    ModalGenericComponent,
    DateCustomComponent,
    AnomaliesPopoverComponent,
    ProjectPopoverComponent,
    EvaluatorPopoverComponent,
    SubscriberTitlesPopoverComponent,
    IconRenderer,
    ImageRenderer,
    TranslateRenderer,
    ActionProjectRenderer,
    IconEvalRenderer,
    ActionEvalRenderer,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    NgbModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    CommonModule,
  ],
  providers: [
    BsModalService,
    ModalGenericService,
    AgGridStateManagerService,
  ],
  entryComponents: [
    ModalGenericComponent
  ],
  exports: [
    DateCustomComponent,
    TranslateModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [ModalGenericService]
    };
  }
}
