import { Routes } from '@angular/router';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FULL_ROUTES } from './routes/full-layout.routes';
import { CONTENT_ROUTES } from './routes/content-layout.routes';
var ɵ0 = { title: 'full Views' }, ɵ1 = { title: 'content Views' };
var appRoutes = [
    {
        path: '',
        redirectTo: '/landing',
        pathMatch: 'full',
    },
    { path: '', component: FullLayoutComponent, data: ɵ0, children: FULL_ROUTES },
    { path: '', component: ContentLayoutComponent, data: ɵ1, children: CONTENT_ROUTES },
    { path: '**', redirectTo: '/landing' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1 };
