<style>
  .content-wrapper{padding: 0px; margin: 0px;}
  .container-fluid{padding: 0px; margin: 0px;}
</style>
<div class="wrapper content-layout-background">
    <div class="content-wrapper">
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
