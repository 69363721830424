var AlertService = /** @class */ (function () {
    function AlertService() {
        this.alerts = [];
    }
    AlertService.prototype.addAlert = function (alert) {
        this.alerts.push(alert);
    };
    AlertService.prototype.getAlerts = function () {
        return this.alerts;
    };
    return AlertService;
}());
export { AlertService };
