import {Injectable} from '@angular/core';

@Injectable()
export class AlertService {

  alerts = [];

  constructor() {}

  addAlert(alert) {
    this.alerts.push(alert);
  }

  getAlerts() {
    return this.alerts;
  }
}
