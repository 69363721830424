import { OnDestroy } from '@angular/core';
import { JhiEventManager, JhiAlertService } from 'ng-jhipster';
import { AlertService } from './alert.service';
import { AuthServerProvider } from '../auth/auth-jwt.service';
import { Principal } from '../auth/principal.service';
import { Router } from '@angular/router';
var AlertErrorComponent = /** @class */ (function () {
    // tslint:disable-next-line: no-unused-variable
    function AlertErrorComponent(jhiAlertService, eventManager, alertService, authServerProvider, principal, router) {
        var _this = this;
        this.jhiAlertService = jhiAlertService;
        this.eventManager = eventManager;
        this.alertService = alertService;
        this.authServerProvider = authServerProvider;
        this.principal = principal;
        this.router = router;
        this.alerts = alertService.getAlerts();
        this.cleanHttpErrorListener = eventManager.subscribe('jhipsterApp.httpError', function (response) {
            var i;
            var httpErrorResponse = response.content;
            switch (httpErrorResponse.status) {
                // connection refused, server not reachable
                case 0:
                    _this.addErrorAlert('Server not reachable', 'error.server.not.reachable');
                    break;
                case 400:
                    var arr = httpErrorResponse.headers.keys();
                    var errorHeader_1 = null;
                    var entityKey_1 = null;
                    arr.forEach(function (entry) {
                        if (entry.startsWith('app-alert-type')) {
                            entityKey_1 = httpErrorResponse.headers.get(entry);
                        }
                        else if (entry.startsWith('app-alert')) {
                            errorHeader_1 = httpErrorResponse.headers.get(entry);
                        }
                    });
                    if (errorHeader_1) {
                        var entityName = entityKey_1;
                        _this.addErrorAlert(errorHeader_1, errorHeader_1, { entityName: entityName });
                    }
                    else if (httpErrorResponse.error !== '' && httpErrorResponse.error.fieldErrors) {
                        var fieldErrors = httpErrorResponse.error.fieldErrors;
                        for (i = 0; i < fieldErrors.length; i++) {
                            var fieldError = fieldErrors[i];
                            // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                            var convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
                            var fieldName = convertedField.charAt(0).toUpperCase() +
                                convertedField.slice(1);
                            _this.addErrorAlert('Error on field "' + fieldName + '"', 'error.' + fieldError.message, { fieldName: fieldName });
                        }
                    }
                    else if (httpErrorResponse.error !== '' && httpErrorResponse.error.message) {
                        _this.addErrorAlert(httpErrorResponse.error.message, httpErrorResponse.error.message, httpErrorResponse.error.params);
                    }
                    else {
                        _this.addErrorAlert(httpErrorResponse.error);
                    }
                    break;
                case 404:
                    _this.addErrorAlert('Not found', 'error.url.not.found');
                    break;
                case 401:
                    _this.addErrorAlert('Session expired or you are logged on another device', 'error.unauthorized');
                    _this.authServerProvider.logout().subscribe(function (res) {
                    });
                    _this.principal.authenticate(null);
                    _this.router.navigate(['/login']);
                    break;
                default:
                    if (httpErrorResponse.error !== '' && httpErrorResponse.error.message) {
                        _this.addErrorAlert(httpErrorResponse.error.message);
                    }
                    else {
                        _this.addErrorAlert(httpErrorResponse.error);
                    }
            }
        });
    }
    AlertErrorComponent.prototype.ngOnDestroy = function () {
        if (this.cleanHttpErrorListener !== undefined && this.cleanHttpErrorListener !== null) {
            this.eventManager.destroy(this.cleanHttpErrorListener);
            this.alerts = [];
        }
    };
    AlertErrorComponent.prototype.addErrorAlert = function (message, key, data) {
        this.alertService.addAlert(this.jhiAlertService.addAlert({
            type: 'danger',
            msg: message,
            timeout: 5000,
            toast: this.jhiAlertService.isToast(),
            scoped: true,
            position: 'top right'
        }, this.alerts));
    };
    return AlertErrorComponent;
}());
export { AlertErrorComponent };
