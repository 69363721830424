<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" style="cursor: default"><img class="logo-rufa" src="assets/img/rufa_logo.svg" alt="RUFA Logo"></a>
  <div class="navbar-collapse" style="display: flex">
    <img class="logo-rufa" src="assets/img/rufa_logo_type.svg" alt="RUFA Logo">
    <ul class="navbar-nav mr-auto"></ul>
    <div class="navbar-text black" *ngIf="account && !isMobile">
      <div ngbDropdown class="d-inline-block">
        <p id="dropdownBasic" aria-expanded="false" ngbDropdownToggle>{{account.firstname}} {{account.lastname}}</p>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic">
          <p class="dropdown-item" style="margin-bottom: 0; cursor: pointer" (click)="logout()">Logout</p>
        </div>
      </div>
    </div>
    <div class="black" *ngIf="account && isMobile">
      <i class="icon-logout lang-text" (click)="logout()"></i>
    </div>
    <div class="lang-it">
      <a [class]="translate.currentLang=='it'?'lang-text active':'lang-text '" (click)="changeLang('it')"><span>ITA</span></a>
    </div>
    <div class="lang-en">
      <a [class]="translate.currentLang=='en'?'lang-text active':'lang-text '" (click)="changeLang('en')"><span>ENG</span></a>
    </div>
  </div>
</nav>
