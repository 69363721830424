import { ElementRef, OnInit } from '@angular/core';
import { LoadingService } from './block/interceptor/loading.service';
import { BsModalService } from 'ngx-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
var AppComponent = /** @class */ (function () {
    function AppComponent(loadingService, deviceService, modalService, translate) {
        this.loadingService = loadingService;
        this.deviceService = deviceService;
        this.modalService = modalService;
        this.translate = translate;
        translate.setDefaultLang('en');
        translate.addLangs(['en', 'it']);
    }
    AppComponent.prototype.ngOnInit = function () {
        var browser = this.deviceService.getDeviceInfo();
        var version = parseInt(browser.browser_version, 10);
        console.log(browser.browser);
        console.log(browser.browser_version);
        var ok = false;
        if (browser.browser.toLowerCase() === 'chrome') {
            // ok = version >= 45;
            ok = version >= 45;
        }
        else if (browser.browser.toLowerCase() === 'firefox') {
            ok = version >= 38;
        }
        else if (browser.browser.toLowerCase() === 'safari') {
            ok = version >= 9;
        }
        else if (browser.browser.toLowerCase() === 'opera') {
            ok = version >= 30;
        }
        else if (browser.browser.toLowerCase() === 'ie') {
            ok = version >= 10;
        }
        else if (browser.browser.toLowerCase() === 'edge') {
            ok = version >= 12;
        }
        if (!ok) {
            this.openModal();
        }
    };
    AppComponent.prototype.openModal = function () {
        this.modalRef = this.modalService.show(this.template, { class: 'modal-sm', backdrop: true, ignoreBackdropClick: true, });
    };
    AppComponent.prototype.isLoading = function () {
        return this.loadingService.isLoading();
    };
    return AppComponent;
}());
export { AppComponent };
