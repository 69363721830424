import { OnInit } from '@angular/core';
import { JhiEventManager } from 'ng-jhipster';
import { Router } from '@angular/router';
import { AuthServerProvider } from '../../block/auth/auth-jwt.service';
import { Principal } from '../../block/auth/principal.service';
import { AccountService } from '../../block/auth/account.service';
import { ModalGenericService } from '../../shared/modal-generic/modal-generic.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { DeviceDetectorService } from 'ngx-device-detector';
var NavbarComponent = /** @class */ (function () {
    function NavbarComponent(authServerProvider, eventManager, principal, accountService, localStorage, sessionStorage, genericModalService, router, translate, deviceService) {
        this.authServerProvider = authServerProvider;
        this.eventManager = eventManager;
        this.principal = principal;
        this.accountService = accountService;
        this.localStorage = localStorage;
        this.sessionStorage = sessionStorage;
        this.genericModalService = genericModalService;
        this.router = router;
        this.translate = translate;
        this.deviceService = deviceService;
    }
    NavbarComponent.prototype.ngOnInit = function () {
        this.translate.addLangs(['en', 'it']);
        if (this.localStorage.retrieve('language') == 'it' || this.sessionStorage.retrieve('language') == 'it') {
            this.translate.use('it');
        }
        else {
            this.translate.use('en');
        }
        this.getAccount();
        this.registerAuthenticationSuccess();
        this.isMobile = this.deviceService.isMobile();
    };
    NavbarComponent.prototype.getAccount = function () {
        var _this = this;
        if (this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken')) {
            this.principal.identity().then(function (account) {
                _this.account = account;
                /* if (this.account.loggedUser.privacyPolicy === null) {
                  this.bsModalRef = this.genericModalService.open({
                    title: 'Accept Privacy',
                    closeButton: null,
                    message: this.account.privacyText.replace('https://www.newton.it/cookie.php', '&nbsp;<a href="https://www.newton.it/cookie.php" target="_blank">https://www.newton.it/cookie.php</a>&nbsp;'),
                    buttons: ['Yes']
                  });
                  this.bsModalRef.content.onClose.subscribe(result => {
                    console.log('ok');
                    this.accountService.acceptPrivacy().subscribe( (data: any) => {
                    });
                  });
                } */
            });
        }
    };
    NavbarComponent.prototype.registerAuthenticationSuccess = function () {
        var _this = this;
        this.eventManager.subscribe('authenticationSuccess', function (message) {
            _this.principal.identity(true).then(function (account) {
                _this.account = account;
            });
        });
    };
    NavbarComponent.prototype.logout = function () {
        this.authServerProvider.logout().subscribe(function (res) { });
        this.principal.authenticate(null);
        this.account = null;
        this.router.navigate(['/landing']);
    };
    NavbarComponent.prototype.loginAs = function (id) {
        var _this = this;
        this.authServerProvider.loginAs(id).subscribe(function (res) {
            _this.eventManager.broadcast({
                name: 'authenticationSuccess',
                content: 'Sending Authentication Success'
            });
        });
    };
    NavbarComponent.prototype.changeLang = function (lang) {
        this.translate.use(lang);
        this.localStorage.store('language', lang);
        this.sessionStorage.store('language', lang);
    };
    return NavbarComponent;
}());
export { NavbarComponent };
