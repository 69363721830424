import { BrowserModule } from '@angular/platform-browser';
import {Injector, LOCALE_ID, NgModule} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {RouterModule} from '@angular/router';
import {AlertModule} from './block/alert/alert.module';
import {AuthModule} from './block/auth/auth.module';
import {LayoutModule} from './layouts/layout.module';
import {LoadingService} from './block/interceptor/loading.service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AuthService} from './block/auth/auth.service';
import {AuthGuard} from './block/auth/auth-guard.service';
import {AuthServerProvider} from './block/auth/auth-jwt.service';
import {AccountService} from './block/auth/account.service';
import {JhiEventManager} from 'ng-jhipster';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {StateStorageService} from './block/auth/state-storage.service';
import {Principal} from './block/auth/principal.service';
import {BsModalRef, BsModalService, ModalModule} from 'ngx-bootstrap';
import {AuthInterceptor} from './block/interceptor/auth.interceptor';
import {ErrorHandlerInterceptor} from './block/interceptor/errorhandler.interceptor';
import {NotificationInterceptor} from './block/interceptor/notification.interceptor';
import {FormsModule} from '@angular/forms';
import {NgbDateParserFormatter, NgbModule, NgbProgressbar} from '@ng-bootstrap/ng-bootstrap';
import {VgCoreModule} from 'videogular2/core';
import {VgControlsModule} from 'videogular2/controls';
import {VgOverlayPlayModule} from 'videogular2/overlay-play';
import {VgBufferingModule} from 'videogular2/buffering';
import {MultiWindowModule} from 'ngx-multi-window';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {SharedModule} from './shared/shared.module';
import {NgbDateItParserFormatter} from './shared/date-custom/ngb-date-it-parser-formatter';
import {Ng5SliderModule} from 'ng5-slider';

registerLocaleData(localeIt, 'it', localeItExtra);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    AuthModule,
    AlertModule,
    RouterModule,
    LayoutModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    Ng5SliderModule,
    NgbModule.forRoot(),
    ModalModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    MultiWindowModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'it'
    },
    LoadingService,
    AuthService,
    AuthGuard,
    AuthServerProvider,
    AccountService,
    JhiEventManager,
    StateStorageService,
    Principal,
    BsModalService,
    BsModalRef,
    LoadingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [
        LocalStorageService,
        SessionStorageService,
        LoadingService
      ]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
      deps: [
        JhiEventManager,
        Injector
      ]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotificationInterceptor,
      multi: true,
      deps: [
        Injector
      ]
    },
    {provide: NgbDateParserFormatter, useClass: NgbDateItParserFormatter}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
