import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Principal } from '../../block/auth/principal.service';
var AgGridStateManagerService = /** @class */ (function () {
    function AgGridStateManagerService($localStorage, $sessionStorage, principal) {
        this.$localStorage = $localStorage;
        this.$sessionStorage = $sessionStorage;
        this.principal = principal;
        this.getAccount();
    }
    AgGridStateManagerService.prototype.saveOrder = function (gridName, gridState) {
        this.$localStorage.store(gridName + '_order_' + this.account.id, gridState);
        this.$sessionStorage.store(gridName + '_order_' + this.account.id, gridState);
    };
    AgGridStateManagerService.prototype.getOrder = function (gridName) {
        return this.$sessionStorage.retrieve(gridName + '_order_' + this.account.id) ||
            this.$localStorage.retrieve(gridName + '_order_' + this.account.id);
    };
    AgGridStateManagerService.prototype.saveState = function (gridName, gridState) {
        this.$localStorage.store(gridName + '_state_' + this.account.id, gridState);
        this.$sessionStorage.store(gridName + '_state_' + this.account.id, gridState);
    };
    AgGridStateManagerService.prototype.getState = function (gridName) {
        return this.$sessionStorage.retrieve(gridName + '_state_' + this.account.id) ||
            this.$localStorage.retrieve(gridName + '_state_' + this.account.id);
    };
    AgGridStateManagerService.prototype.saveGroup = function (gridName, gridState) {
        this.$localStorage.store(gridName + '_group_' + this.account.id, gridState);
        this.$sessionStorage.store(gridName + '_group_' + this.account.id, gridState);
    };
    AgGridStateManagerService.prototype.getGroup = function (gridName) {
        return this.$sessionStorage.retrieve(gridName + '_group_' + this.account.id) ||
            this.$localStorage.retrieve(gridName + '_group_' + this.account.id) || [];
    };
    AgGridStateManagerService.prototype.resetState = function (gridName) {
        this.$localStorage.clear(gridName + '_' + this.account.id);
        this.$sessionStorage.clear(gridName + '_' + this.account.id);
    };
    AgGridStateManagerService.prototype.getAccount = function () {
        var _this = this;
        this.principal.identity().then(function (account) {
            _this.account = account;
        });
    };
    return AgGridStateManagerService;
}());
export { AgGridStateManagerService };
