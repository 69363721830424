import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
var ErrorHandlerInterceptor = /** @class */ (function () {
    function ErrorHandlerInterceptor(eventManager, injector) {
        this.eventManager = eventManager;
        this.injector = injector;
    }
    ErrorHandlerInterceptor.prototype.intercept = function (request, next) {
        // return next.handle(request).do((event: HttpEvent<any>) => {}, (err: any) => {
        //   if (err instanceof HttpErrorResponse) {
        //     if (!(err.status === 401 && (err.message === '' || (err.url && err.url.indexOf('/users/me') >= 0)))) {
        //       if (this.eventManager !== undefined) {
        //         this.eventManager.broadcast({name: 'jhipsterApp.httpError', content: err});
        //
        //         const router: Router = this.injector.get(Router);
        //         switch (err.status) {
        //           // connection refused, server not reachable
        //           case 0:
        //             router.navigate(['/500']);
        //             break;
        //
        //           case 400:
        //             // router.navigate(['/']);
        //             break;
        //
        //           case 404:
        //             router.navigate(['/404']);
        //             break;
        //
        //           default:
        //             router.navigate(['/500']);
        //         }
        //
        //       }
        //     }
        //   }
        // });
        var _this = this;
        return next.handle(request).pipe(catchError(function (err) {
            if (!(err.status === 401 && (err.message === ''
                || (err.url && err.url.indexOf('/users/me') >= 0) || (err.url && err.url.indexOf('/auth/login') >= 0)))) {
                if (_this.eventManager !== undefined) {
                    _this.eventManager.broadcast({ name: 'jhipsterApp.httpError', content: err });
                }
                var router = _this.injector.get(Router);
                switch (err.status) {
                    // connection refused, server not reachable
                    case 0:
                        router.navigate(['/0']);
                        break;
                    case 400:
                        // router.navigate(['/']);
                        break;
                    case 404:
                        router.navigate(['/404']);
                        break;
                    default:
                        router.navigate(['/500']);
                }
            }
            return throwError(err);
        }));
    };
    return ErrorHandlerInterceptor;
}());
export { ErrorHandlerInterceptor };
