import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalGenericComponent } from './modal-generic.component';
var ModalGenericService = /** @class */ (function () {
    function ModalGenericService(modalService) {
        this.modalService = modalService;
        this.modalIsOpen = false;
        this.setModalListner();
    }
    ModalGenericService.prototype.setModalListner = function () {
        var _this = this;
        this.modalService.onHide.subscribe(function () {
            _this.modalIsOpen = false;
        });
        this.modalService.onShow.subscribe(function () {
            _this.modalIsOpen = true;
        });
    };
    ModalGenericService.prototype.isOpen = function () {
        return this.modalIsOpen;
    };
    ModalGenericService.prototype.open = function (options) {
        this.bsModalRef = this.modalService.show(ModalGenericComponent, { class: 'modal-md' });
        this.bsModalRef.content.options = options;
        this.bsModalRef.content.closeBtnName = 'Close';
        return this.bsModalRef;
    };
    ModalGenericService.prototype.openNavigate = function (options) {
        this.bsModalRef = this.modalService.show(ModalGenericComponent, { class: 'modal-md', ignoreBackdropClick: true });
        options.isNavigate = true;
        this.bsModalRef.content.options = options;
        this.bsModalRef.content.closeBtnName = 'Close';
        return this.bsModalRef;
    };
    return ModalGenericService;
}());
export { ModalGenericService };
